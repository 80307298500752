import { css } from '@emotion/core'
import Bg1 from '../../images/videos/1.png'
import Bg2 from '../../images/videos/2.png'
import Bg3 from '../../images/videos/3.png'
import Bg4 from '../../images/videos/4.png'
import Bg5 from '../../images/videos/1984-1.png'
import Bg6 from '../../images/videos/1984-2.png'
import Bg7 from '../../images/videos/1984-3.png'
import Bg8 from '../../images/videos/1984-4.png'
import Bg9 from '../../images/videos/1984-5.png'
import Bg10 from '../../images/videos/competicao-1.jpeg'
import Bg11 from '../../images/videos/competicao-2.jpeg'
import Bg12 from '../../images/videos/competicao-3.jpeg'
import Bg13 from '../../images/videos/competicao-4.jpeg'
import Bg14 from '../../images/videos/competicao-5.jpeg'
import Bg15 from '../../images/videos/competicao-6.jpeg'

export const videoBox = css`
  border-radius: 5px;
  flex-direction: column;
  outline: none;
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #fff;
    text-align: center;
  }
  img {
    cursor: pointer;
  }
`

export const bg1 = css`
  background: url(${Bg1}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`
export const bg2 = css`
  background: url(${Bg2}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`
export const bg3 = css`
  background: url(${Bg3}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`
export const bg4 = css`
  background: url(${Bg4}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`
export const bg5 = css`
  background: url(${Bg5}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`
export const bg6 = css`
  background: url(${Bg6}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`
export const bg7 = css`
  background: url(${Bg7}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`
export const bg8 = css`
  background: url(${Bg8}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`
export const bg9 = css`
  background: url(${Bg9}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`
export const bg10 = css`
  background: url(${Bg10}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`
export const bg11 = css`
  background: url(${Bg11}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`
export const bg12 = css`
  background: url(${Bg12}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`
export const bg13 = css`
  background: url(${Bg13}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`
export const bg14 = css`
  background: url(${Bg14}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`
export const bg15 = css`
  background: url(${Bg15}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  display: flex !important;
  width: 320px;
  height: 283px;
  flex-direction: column;
  outline: none;
  cursor: pointer;
`

export const modalVideo = css`
  width: 940px;
  height: 564px;
  @media (min-width: 260px) and (max-width: 736px) {
    width: 300px;
    height: 210px;
  }
`